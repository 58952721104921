import React from "react";
import "./Contact.css";
import { Typography } from '@mui/material';

export default function Contact() {
    return (
        <div className="Contact">

            <div className="ContactTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Contact
                </Typography>
            </div>
            <Typography variant="h5" component="div" gutterBottom>
                FURO : Panem et circenses
            </Typography>
            <a href={"https://www.google.be/maps/place/Zimmerplein+7,+2500+Lier"} target="_blank">
                <Typography variant="body1" component="div" gutterBottom>
                    Zimmerplein 7, 2500 Lier
                </Typography>
            </a>
            <a href={"tel:033447741"}>
                <Typography variant="body1" component="div" gutterBottom>
                    03/344 77 41
                </Typography>
            </a>
            <a href={"mailto:info@restofuro.be"}>
                <Typography variant="body1" component="div" gutterBottom>
                    info@restofuro.be
                </Typography>
            </a>
            <div className="About" />
        </div >
    );
}