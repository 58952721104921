import React from "react";
import "./Darts.css";
import { Typography } from '@mui/material';
import darts1 from '../assets/darts.png'

export default function Darts() {
    return (
        <div className="Darts">
            <div className="DartsTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Darts
                </Typography>
            </div>
            <img src={darts1} alt="darts1" className="DartsImage" />
        </div>
    );
}