import { useEffect } from "react";
import { Amplify, API } from "aws-amplify";
import awsconfig from "../.aws-config/awsconfig.json";

Amplify.configure(awsconfig);

const GetActivities = async (startDate, endDate) => {
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];
  return API.get("furo", "/get-activities", { body: { startDate: formattedStartDate, endDate: formattedEndDate } });
};

const CreateBooking = async (reservationData) => {
  return API.post("furo", "/create-booking", { body: reservationData });
};

export {
  GetActivities,
  CreateBooking
};
