import React from "react";
import "./Drinks.css";
import { Typography } from '@mui/material';
import drinks1 from '../assets/drinks-1.png'
import drinks2 from '../assets/drinks-2.png'
import drinks3 from '../assets/drinks-3.png'
import drinks4 from '../assets/drinks-4.png'
import drinks5 from '../assets/drinks-5.png'

export default function Drinks() {
    return (
        <div className="Drinks">
            <div className="DrinksTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Drinks
                </Typography>
            </div>
            <img src={drinks1} alt="drinks1" className="DrinksImage" />
            <img src={drinks2} alt="drinks2" className="DrinksImage" />
            <img src={drinks3} alt="drinks3" className="DrinksImage" />
            <img src={drinks4} alt="drinks4" className="DrinksImage" />
            <img src={drinks5} alt="drinks5" className="DrinksImage" />
        </div>
    );
}