import React from "react";
import "./Karaoke.css";
import { Typography } from '@mui/material';
import karaoke1 from '../assets/karaoke-1.png'

export default function Darts() {
    return (
        <div className="Karaoke">
            <div className="KaraokeTitle">
                <Typography variant="h1" component="div" gutterBottom>
                    Karaoke
                </Typography>
            </div>
            <img src={karaoke1} alt="karaoke1" className="KaraokeImage" />
        </div>
    );
}