import React from "react";
import "./AppHeader.css";
import { AppBar, Toolbar, Tabs, Tab } from '@mui/material';

export default function AppHeader(props) {
    return (
        <AppBar color='secondary' position="sticky">
            <Toolbar>
                <div className="AppHeaderLogoFuro" onClick={() => {
                    props.scrollToMovie();
                }} />
                <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    <Tab label="Apero" onClick={() => {
                        props.scrollToApero();
                    }} />
                    <Tab label="Drinks" onClick={() => {
                        props.scrollToDrinks();
                    }} />
                    <Tab label="Dinner" onClick={() => {
                        props.scrollToDinner();
                    }} />
                    <Tab label="Darts" onClick={() => {
                        props.scrollToDarts();
                    }} />
                    <Tab label="Karaoke" onClick={() => {
                        props.scrollToKaraoke();
                    }} />
                    <Tab label="Contact" onClick={() => {
                        props.scrollToContact();
                    }} />
                </Tabs>
            </Toolbar>
        </AppBar>
    );
}
