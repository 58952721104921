import { useRef, useState } from "react";
import './App.css';
import AppHeader from "./components/AppHeader";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MoviePlayer from './components/MoviePlayer';
import Concept from "./components/Concept";
import Apero from './components/Apero';
import Drinks from './components/Drinks';
import Dinner from './components/Dinner';
import Darts from './components/Darts';
import Karaoke from './components/Karaoke';
import Contact from './components/Contact';
import FloatingReservationButton from './components/FloatingReservationButton';
import { Button } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: '#6d975b',
      main: '#497d33',
      dark: '#335723'
    },
    secondary: {
      light: '#f3e39b',
      main: '#f0dd83',
      dark: '#a89a5b'
    }
  },
  typography: {
    fontFamily: 'Quicksand'
  }
});

function App() {
  const [showIframe, setShowIframe] = useState(false); // State to toggle iframe visibility

  const toggleIframe = () => {
    setShowIframe((prevState) => !prevState);
  };

  const movieRef = useRef(null)
  const aperoRef = useRef(null)
  const drinksRef = useRef(null)
  const dinnerRef = useRef(null)
  const dartsRef = useRef(null)
  const karaokeRef = useRef(null)
  const contactRef = useRef(null)

  const scrollToMovie = () => {
    movieRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToApero = () => {
    aperoRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToDrinks = () => {
    drinksRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToDinner = () => {
    dinnerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToDarts = () => {
    dartsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToKaraoke = () => {
    karaokeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ float: "left", clear: "both" }} ref={movieRef} />
      <AppHeader
        scrollToMovie={scrollToMovie}
        scrollToApero={scrollToApero}
        scrollToDrinks={scrollToDrinks}
        scrollToDinner={scrollToDinner}
        scrollToDarts={scrollToDarts}
        scrollToKaraoke={scrollToKaraoke}
        scrollToContact={scrollToContact} />
      <MoviePlayer />
      <Concept />
      <div style={{ float: "left", clear: "both" }} ref={aperoRef} />
      <Apero />
      <div style={{ float: "left", clear: "both" }} ref={drinksRef} />
      <Drinks />
      <div style={{ float: "left", clear: "both" }} ref={dinnerRef} />
      <Dinner />
      <div style={{ float: "left", clear: "both" }} ref={dartsRef} />
      <Darts />
      <div style={{ float: "left", clear: "both" }} ref={karaokeRef} />
      <Karaoke />
      <div style={{ float: "left", clear: "both" }} ref={contactRef} />
      <Contact />
      <Button
        variant="contained"
        color="primary"
        onClick={toggleIframe}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1001,
          padding: "10px 20px",
          backgroundColor: "#007BFF",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        {showIframe ? "Sluit reserveringsscherm" : "Reserveer hier"}
      </Button>

      {/* Iframe container */}
      {showIframe && (
        <div
          style={{
            position: "fixed",
            top: "60px",
            right: "0",
            width: "400px",
            maxWidth: "100%",
            height: "80%",
            zIndex: 1000,
            background: "white",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <iframe
            seamless
            frameBorder="0"
            width="100%"
            height="100%"
            src="https://www.reservaria.be/start.php?companyId=18&groupId=0&iframe=1"
            title="Reservation"
            style={{ border: "none" }}
          />
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
