import React from "react";
import "./Concept.css";
import { Typography } from '@mui/material';
import concept1 from '../assets/concept-1.png'

export default function Concept() {
    return (
        <div className="Concept">
            <div className="ConceptLogo" />
            <Typography variant="h5" component="div" gutterBottom>
                Lach, speel, drink, eet, feest en wees gelukkig !
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                FURO is een uniek brasserieconcept dat gebouwd is rond spel en samenzijn.
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                Reserveer je professionele dartsbaan of karaoke of neem gewoon een bordspel uit de kast.
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                Je kan ook gewoon iets komen drinken of eten natuurlijk, zonder te spelen.
                We ontvangen je altijd met de glimlach !
            </Typography>
            <img src={concept1} alt="concept1" className="ConceptImage" />
        </div >
    );
}